import { Box, Container, Typography, Grid, Card } from "@mui/material";
import doctor1 from "../assets/doctor1.png";
import doctor2 from "../assets/doctor2.png";
import nurse1 from "../assets/nurse1.png";
import nurse2 from "../assets/nurse2.png";
import receptionist from "../assets/receptionist-erin.png";
import InfoSection from "../components/InfoSection";

const DividingLine = () => {
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "1185px",
        height: "1px",
        backgroundColor: "black",
        mb: 2,
        margin: "0 auto",
      }}
    />
  );
};

export default function AboutUs() {
  const teamMembers = [
    {
      name: "Dr. Gamal EL-Ghobashy",
      title: "Medical Director",
      qualifications: "MB; BCH, DIPOBS, RNZCOG, DPD (DERMATOLOGY) (UK), FRACGP",
      descriptions: [
        "Dr. Gamal EL-Ghobashy, the owner and Medical Director of Lotus Skin Clinic and Cosmetic Centre, is a highly experienced physician with a rich background in medicine. With over 20 years of active involvement in the Gold Coast community, Dr. EL-Ghobashy is a trusted figure known for his dedication to patient care.",
        "Having previously practiced in Egypt and New Zealand, Dr. EL-Ghobashy brings a wealth of diverse experience to our clinic. He is a Fellow of the Royal College of General Practitioners and has been serving patients in Australia for close to two decades. Dr. EL-Ghobashy is also certified by the American License Board (USMLE).",
        "His longstanding relationships with patients spanning over a decade are a testament to his commitment to personalized care and excellence in medicine. Dr. EL-Ghobashy's leadership ensures that Lotus Skin Clinic and Cosmetic Centre maintains the highest standards of care and continues to serve the Gold Coast community with distinction.",
      ],
      image: doctor2,
    },
    {
      name: "Dr. Timothy Ibrahim",
      title: "Accredited Skin Cancer Doctor",
      qualifications:
        "Bachelor of Medicine and Bachelor of Surgery from the University of Adelaide",
      descriptions: [
        "Dr. Timothy Ibrahim is a highly skilled skin cancer doctor with a diverse background in general practice and surgery. With over 8000+ skin checks conducted in a region known for its high concentration of skin cancer cases, Dr. Ibrahim brings extensive experience to his practice.",
        "He obtained his Bachelor of Medicine and Bachelor of Surgery from the University of Adelaide and completed his general practice training at the Royal Australian College of General Practitioners. Dr. Ibrahim holds an advanced clinical certificate in skin cancer medicine and surgery, further enhancing his expertise in the field. With his wealth of experience and commitment to excellence, patients can trust Dr. Timothy Ibrahim to deliver personalized and effective skin cancer care.",
        "Dr. Tim's friendly demeanour and genuine passion for educating others about his work shine through in his social media presence. Follow him for insights into skin cancer prevention, diagnosis, and treatment. Facebook: Timothy Ibrahim's Facebook Profile Instagram: Timothy Ibrahim's Instagram Profile",
      ],
      image: doctor1,
    },
    {
      name: "Sally Hotere",
      title: "Practice Manager / Senior Registered Nurse",
      qualifications: "Registered Nurse and Cosmetic Injectables Specialist",
      descriptions: [
        "Sally Hotere, a valued member of our team at Lotus Skin Clinic, brings a wealth of experience and expertise. Raised in Ormeau, Queensland, Sally is a proud graduate of Griffith University, where she earned her Bachelor of Nursing. With her friendly and professional demeanour, she provides exceptional care to her patients.",
        "Sally's advanced training in cosmetic procedures includes anti-wrinkle injections, dermal fillers, and microneedling. Her meticulous approach ensures that every patient receives personalized and effective treatment.",
        "At Lotus Skin Clinic, Sally is committed to enhancing the natural beauty and confidence of her patients through her expert skills in both nursing and aesthetics.",
      ],
      image: nurse1,
    },
    {
      name: "Yuliya Kalmykova",
      title: "Registered Nurse / Injectable Nurse",
      qualifications: "Registered Nurse and Cosmetic Injectables Specialist",
      descriptions: [
        "Meet Yuliya Kalmykova, our esteemed Registered Nurse and Cosmetic Injectables Specialist at Lotus Skin Clinic and Cosmetic Centre. Yulia’s background is Russian but she was raised in Kazakhstan before finding her home on the Gold Coast. A graduate of St. Stephen’s College, she has deep roots in the local community.",
        "Yuliya's journey continued as she pursued her passion for nursing, culminating in her recent graduation from Griffith University as a Registered Nurse. Known for her wonderful, friendly, and warm demeanour, Yuliya takes pride in providing exceptional care to her patients.",
        "With a keen eye for aesthetics and a steady hand, she excels in cosmetic injectables, specializing in both botulinum toxins and filler injections.",
      ],
      image: nurse2,
    },
    {
      name: "Erin Mildenhall",
      title: "Receptionist",
      descriptions: [
        "Erin Mildenhall brings over five years of experience as a skilled secretary with a keen eye for detail and excellent communication skills",
        "A dual citizen of the UK and Australia, Erin grew up in Griffith, NSW, and has lived in London and Dundee, Scotland. Most recently, she worked as a Legal Secretary at Bassets Solicitors in London, where she supported solicitors, managed client communications, and prepared legal documents.",
        "With her strong organizational skills and welcoming demeanor, Erin is a fantastic addition to our team.",
      ],
      image: receptionist,
    },
  ];

  return (
    <Box
      sx={{
        py: 5,
        backgroundColor: "#f0f0f0",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 15 }}>
        {/* About Us Section */}
        <Container maxWidth="md" sx={{ textAlign: "center", mb: 8, py: 5 }}>
          <Typography variant="h3" gutterBottom fontWeight="bold" color="black">
            ABOUT US
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Welcome to Lotus Skin Clinic and Cosmetic Centre, where we offer a
            comprehensive range of skin care services tailored to meet your
            needs. Since 2012, our clinic has provided top-notch skin cancer
            care, from initial checks to post-procedural follow-up. Situated in
            the vibrant Harbourtown Shopping Centre, we are proud to serve the
            local community with excellence and compassion.
          </Typography>
          <Typography variant="body1" color="textPrimary" sx={{ mt: 2 }}>
            Our highly trained team of doctors and nurses stay current and
            continually enhance their skills through ongoing training and
            education. All our doctors are fully accredited by the Skin Cancer
            College of Australia, providing you with the assurance that you are
            in expert hands.
          </Typography>
        </Container>

        {/* Meet the Team Section */}
        <Container maxWidth="md" sx={{ textAlign: "center" }}>
          <Typography
            variant="h3"
            gutterBottom
            fontWeight="bold"
            color="black"
            id="meet-the-team"
          >
            MEET THE TEAM
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "1px",
              backgroundColor: "black",
              mb: 5,
            }}
          />
        </Container>
        <Grid display={"grid"}>
          {teamMembers.map((member) => {
            return (
              <div>
                <Grid item>
                  <Box
                    sx={{
                      py: 5,
                      backgroundColor: "#f0f0f0",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <Container maxWidth="lg">
                      <InfoSection
                        image={member.image}
                        title={member.name}
                        subtitle={member.title}
                        qualifications={member.qualifications}
                        descriptions={member.descriptions}
                        buttonText={undefined}
                        onButtonClick={undefined}
                      />
                    </Container>
                  </Box>
                </Grid>
                <DividingLine />
              </div>
            );
          })}
        </Grid>

        {/* Billing Section */}
        <Card sx={{ backgroundColor: "#ffffff", mt: 8, p: 6 }}>
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="h3"
              gutterBottom
              fontWeight="bold"
              color="black"
              id="billing"
            >
              BILLING POLICY
            </Typography>
            <Box
              sx={{
                width: "100%",
                height: "1px",
                backgroundColor: "black",
                mb: 2,
              }}
            />
          </Box>
          <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
            At Lotus Skin Clinic, we are committed to providing transparent and
            accessible healthcare. We are pleased to announce that we are
            primarily a bulk billing clinic. Please review our billing policy
            below:
          </Typography>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            sx={{ mb: 2 }}
          >
            Bulk Billing Services
          </Typography>
          <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
            Skin checks & procedures will be bulk billed for:
          </Typography>
          <ul style={{ textAlign: "left", paddingLeft: "16px" }}>
            <li>Individuals 18 and under</li>
            <li>DVA cardholders</li>
            <li>Pensioners</li>
            <li>Patients over 65 years old</li>
          </ul>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            sx={{ mb: 2 }}
          >
            Mixed Billing Services
          </Typography>
          <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
            For patients aged 19 to 64 without a pension card or DVA card:
          </Typography>
          <ul style={{ textAlign: "left", paddingLeft: "16px" }}>
            <li>
              <strong>Skin checks:</strong> Priced at $85, with a Medicare
              rebate of $41.40 provided on the spot. This results in an
              out-of-pocket cost of $43.60.
            </li>
            <li>
              <strong>Procedures:</strong> We strive to bulk bill procedures
              where possible, but some may incur out-of-pocket expenses. Any
              additional costs will be clearly discussed with patients prior to
              payment or surgery.
            </li>
          </ul>
          <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
            All patients are required to pay for skin checks at the end of their
            appointment.
          </Typography>
          <Typography variant="body1" color="textPrimary" sx={{ mb: 2 }}>
            We aim to ensure fair and sustainable access to high-quality care
            for all our patients. If you have any questions or concerns about
            our billing policy, please don't hesitate to reach out to our team.
            We're here to provide clarity and support as we continue to
            prioritize your health and wellbeing.
          </Typography>
        </Card>
      </Container>
    </Box>
  );
}
